import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import Link from "gatsby-link";
import styled from "styled-components";
import { colors, fonts, breakpoints } from "../style-utilities/variables";

// import components
import TinyPagination from "../components/TinyPagination";
import Filters from "../components/Filters";
import CaseStudiesGrid from "../components/CaseStudies/CaseStudiesGrid";
import ContactBanner from "../components/ContactBanner";
import Hero from "../components/Hero";
import Arrow from "../components/Arrow";
import ArrowLeft from "../components/ArrowLeft";

import DefaultSharingImage from "./../static/ansira-logo-sharing.png";

const StyledCaseStudies = styled.main`
  margin: 0 auto;
  max-width: 1500px;
  .contact-us {
    border-top: 1px solid ${colors.secondaryGray};
    & > div {
      padding: 70px 0;
    }
  }
`;
// pass gridCardType into grid as minimal or showTags to show or hide date and tags

class CaseStudies extends Component {
  constructor(props) {
    super(props);

    this.state = {
      setSortValue: {},
      setTopicValue: {},
      setServiceValue: {},
      setVerticalValue: {},
      sortValue: "",
      topicValue: "",
      topicId: "",
      serviceValue: "",
      serviceId: "",
      verticalValue: "",
      valueId: "",
      isBrowser: typeof window !== "undefined",
      selectedPageId: 1
    };

    this.changePage = this.changePage.bind(this);
    this.renderBtnNumber = this.renderBtnNumber.bind(this);
  }

  /**
   * METHOD: Scroll to top on pagination click needs scrollStep
   */
  scrollStep() {
    if (window.pageYOffset === 0) {
      clearInterval(this.state.intervalId);
    }
    window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
  }
  scrollToTop() {
    let intervalId = setInterval(
      this.scrollStep.bind(this),
      this.props.delayInMs
    );
    this.setState({ intervalId: intervalId });
  }

  /**
   * METHOD: Change page on Pagination
   * @param: id of the current page
   */
  changePage(id) {
    this.setState(prevState => {
      return {
        ...prevState,
        selectedPageId: id
      };
    });
  }
  /**
   * METHOD: for button Prev and Next on Pagination
   * @param: id of the current page
   */
  buttonPageClick(id) {
    let { selectedPageId } = this.state;
    switch (id) {
      case "<":
        this.changePage(selectedPageId - 1);
        break;
      case ">":
        this.changePage(selectedPageId + 1);
        break;
      default:
        this.changePage(id);
        break;
    }
    this.scrollToTop();
  }
  /**
   * METHOD: Render button to the UI
   * @param: id of the current page
   */
  renderBtnNumber = id => {
    let { selectedPageId } = this.state;

    let buttonContent = id;
    if (id == "<") {
      buttonContent = <ArrowLeft />;
    }
    if (id == ">") {
      buttonContent = <Arrow />;
    }

    return (
      <button
        onClick={this.buttonPageClick.bind(this, id)}
        key={id}
        className={`page ${selectedPageId === id ? "selected-page" : ""}`}
      >
        {buttonContent}
      </button>
    );
  };

  /* Get Topic Service and Vertical value from filter and store for pagination*/
  handleTopic = e => {
    if (this.state.isBrowser) {
      window.localStorage.setItem(
        "handleTopic",
        e ? JSON.stringify(e) : window.localStorage.removeItem("handleTopic")
      );
    }
    this.setState({
      topicValue: e ? e.value : null,
      setTopicValue: e ? e : null,
      selectedPageId: 1
    });
  };
  handleService = e => {
    if (this.state.isBrowser) {
      window.localStorage.setItem(
        "handleService",
        e ? JSON.stringify(e) : window.localStorage.removeItem("handleService")
      );
    }
    this.setState({
      serviceValue: e ? e.value : null,
      setServiceValue: e ? e : null,
      selectedPageId: 1
    });
  };
  handleVertical = e => {
    if (this.state.isBrowser) {
      window.localStorage.setItem(
        "handleVertical",
        e ? JSON.stringify(e) : window.localStorage.removeItem("handleVertical")
      );
    }
    this.setState({
      verticalValue: e ? e.value : null,
      setVerticalValue: e ? e : null,
      selectedPageId: 1
    });
  };
  handleSort = e => {
    if (this.state.isBrowser) {
      window.localStorage.setItem(
        "handleSort",
        e ? JSON.stringify(e) : window.localStorage.removeItem("handleSort")
      );
    }
    this.setState({
      sortValue: e ? e.value : null,
      setSortValue: e ? e : null
    });
  };

  render() {
    const props = this.props;
    let topics = props.data.allWordpressWpTopic;
    let service = props.data.allWordpressWpOurSolutions;
    let vertical = props.data.allWordpressWpVertical;
    let posts = props.data.allWordpressWpCaseStudy.edges;
    let page = props.data.wordpressPage;

    const hero_headline = page.acf.hero_headline;
    const hero_sub_headline = page.acf.hero_sub_headline;
    const contact_headline = page.acf.contact_headline;
    const contact_cta = page.acf.contact_cta;
    const contact_url = page.acf.contact_url;

    let filteredPosts = [];
    const topicsList = [];
    const servicesList = [];
    const verticalsList = [];
    let usedTopics = [];
    let usedServices = [];
    let usedVerticals = [];
    let selectedValuesArray = [];
    let queryParameter = "";
    let queryNumber = "";
    let topicPlaceholder = "Topic";

    if (typeof document !== "undefined") {
      queryParameter = window.location.href.split("=");
      queryNumber = parseInt(queryParameter[1]);
    }

    if (queryParameter.length > 1) {
      selectedValuesArray.push(parseInt(queryParameter[1]));
    }

    //if item in Array of selected state matches items in single post, push that post to a new posts array and send to Blog Grid
    if (this.state.topicValue != "" && this.state.topicValue != null) {
      selectedValuesArray.push(this.state.topicValue);
    }
    if (this.state.serviceValue != "" && this.state.serviceValue != null) {
      selectedValuesArray.push(this.state.serviceValue);
    }
    if (this.state.verticalValue != "" && this.state.verticalValue != null) {
      selectedValuesArray.push(this.state.verticalValue);
    }

    posts.map((post, i) => {
      let thisPostsValues = [];

      posts[i].node.topic.map((topic, j) => {
        usedTopics.push(topic);
        thisPostsValues.push(posts[i].node.topic[j]);
        if (
          posts[i].node.topic[j] == this.state.topicValue &&
          this.state.serviceValue == "" &&
          this.state.verticalValue == ""
        ) {
          filteredPosts.push(post);
        }
      });

      posts[i].node.our_solutions.map((services, k) => {
        usedServices.push(services);
        thisPostsValues.push(posts[i].node.our_solutions[k]);
        if (
          posts[i].node.our_solutions[k] == this.state.serviceValue &&
          this.state.topicValue == "" &&
          this.state.verticalValue == ""
        ) {
          filteredPosts.push(post);
        }
      });
      posts[i].node.vertical.map((vertical, l) => {
        usedVerticals.push(vertical);
        thisPostsValues.push(posts[i].node.vertical[l]);
        if (
          posts[i].node.vertical[l] == this.state.verticalValue &&
          this.state.serviceValue == "" &&
          this.state.topicValue == ""
        ) {
          filteredPosts.push(post);
        }
      });
      if (
        selectedValuesArray.every(function(item) {
          return thisPostsValues.indexOf(item) !== -1;
        }) == true
      ) {
        filteredPosts.push(post);
      }
    });

    //Check if no results on filter to trigger GTM event
    if (typeof document !== "undefined") {
      let arrPostItems = document.getElementsByClassName("insights-grid");
      let postItem = arrPostItems.item(0);
      if (postItem != null && postItem.innerHTML === "") {
        if (filteredPosts.length === 0) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "noFilterResults"
          });
        }
      }
    }

    //function to remove duplicates in filteredPosts array
    function removeDuplicates(arr) {
      const final = [];
      arr.map((e, i) => !final.includes(e) && final.push(e));
      return final;
    }

    removeDuplicates(usedTopics);
    removeDuplicates(usedServices);
    filteredPosts = removeDuplicates(filteredPosts);

    let allUsedTopics = [];
    let allUsedServices = [];
    let allUsedVerticals = [];

    topics.edges.map((topic, index) => {
      for (var i = 0; i < usedTopics.length; i++) {
        if (usedTopics[i] == topics.edges[index].node.wordpress_id) {
          allUsedTopics.push(topic);
        }
      }
    });

    service.edges.map((serv, index) => {
      for (var i = 0; i < usedServices.length; i++) {
        if (usedServices[i] == service.edges[index].node.wordpress_id) {
          allUsedServices.push(serv);
        }
      }
    });

    vertical.edges.map((vert, index) => {
      for (var i = 0; i < usedVerticals.length; i++) {
        if (usedVerticals[i] == vertical.edges[index].node.wordpress_id) {
          allUsedVerticals.push(vert);
        }
      }
    });

    for (var key in topics.edges) {
      if (topics.edges[key].node.slug == this.state.topicValue) {
        this.setState({ topicValue: topics.edges[key].node.wordpress_id });
      }
      if (topics.edges[key].node.wordpress_id == queryNumber) {
        topicPlaceholder = topics.edges[key].node.name;
      }
    }

    for (var key in service.edges) {
      if (service.edges[key].node.slug == this.state.serviceValue) {
        this.setState({ serviceValue: service.edges[key].node.wordpress_id });
      }
    }
    for (var key in vertical.edges) {
      if (vertical.edges[key].node.slug == this.state.verticalValue) {
        this.setState({ verticalValue: vertical.edges[key].node.wordpress_id });
      }
    }

    //sort all posts by most popular
    let popularPosts = [];
    if (this.state.sortValue == "most-popular") {
      popularPosts = Object.assign([], posts).sort(function(a, b) {
        return b.node.acf.popularity_num - a.node.acf.popularity_num;
      });
      posts = popularPosts;
    } else {
      posts = props.data.allWordpressWpCaseStudy.edges;
    }

    //sort filtered posts by most popular
    let popularFiltered = [];
    if (this.state.sortValue == "most-popular" && filteredPosts != 0) {
      popularFiltered = Object.assign([], filteredPosts).sort(function(a, b) {
        return b.node.acf.popularity_num - a.node.acf.popularity_num;
      });
      filteredPosts = popularFiltered;
    } else if (this.state.sortValue == "most-recent" && filteredPosts != 0) {
      filteredPosts = filteredPosts;
    }

    // Here we are grabbing content for filter dropdowns, the react-select library requires a specific format so we need to rename (name, slug) to be (value, label)
    removeDuplicates(allUsedTopics).forEach((item, index) => {
      topicsList[index] = {
        value: item.node.slug,
        label: item.node.name
      };
    });

    removeDuplicates(allUsedServices).forEach((item, index) => {
      servicesList[index] = {
        value: item.node.slug,
        label: item.node.name
      };
    });

    removeDuplicates(allUsedVerticals).forEach((item, index) => {
      verticalsList[index] = {
        value: item.node.slug,
        label: item.node.name
      };
    });

    const itemPerPage = 6;
    let defaultPosts = [...posts];
    defaultPosts = defaultPosts.splice(
      (this.state.selectedPageId - 1) * itemPerPage,
      itemPerPage
    );
    let conditionalPosts = removeDuplicates(filteredPosts);
    conditionalPosts = conditionalPosts.splice(
      (this.state.selectedPageId - 1) * itemPerPage,
      itemPerPage
    );

    return (
      <StyledCaseStudies>
        <Helmet>
          {/* General tags */}
          <title>Case Studies and Client Results | Ansira</title>
          <meta
            name="description"
            content="These marketing case studies demonstrate how Ansira delivers client results in CRM and loyalty, through-channel marketing automation, and media."
          />
          <meta name="image" content={DefaultSharingImage} />

          {/* OpenGraph tags */}
          <meta property="og:url" content="/results/case-studies" />
          <meta property="og:type" content="website" />

          <meta
            property="og:title"
            content="Case Studies and Client Results | Ansira"
          />
          <meta
            property="og:description"
            content="These marketing case studies demonstrate how Ansira delivers client results in CRM and loyalty, through-channel marketing automation, and media."
          />
          <meta property="og:image" content={DefaultSharingImage} />

          {/* Twitter Card tags */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:creator" content="https://twitter.com/ansira" />
          <meta
            name="twitter:title"
            content="Case Studies and Client Results | Ansira"
          />
          <meta
            name="twitter:description"
            content="These marketing case studies demonstrate how Ansira delivers client results in CRM and loyalty, through-channel marketing automation, and media."
          />
          <meta name="twitter:image" content={DefaultSharingImage} />
        </Helmet>
        <Hero headline={hero_headline} sub={hero_sub_headline} />
        <div className="post-area page-container">
          <Filters
            setTopicValue={
              this.state.setTopicValue !== null &&
              Object.getOwnPropertyNames(this.state.setTopicValue).length !== 0
                ? this.state.setTopicValue
                : null
            }
            setServiceValue={
              this.state.setServiceValue !== null &&
              Object.getOwnPropertyNames(this.state.setServiceValue).length !==
                0
                ? this.state.setServiceValue
                : null
            }
            setVerticalValue={
              this.state.setVerticalValue !== null &&
              Object.getOwnPropertyNames(this.state.setVerticalValue).length !==
                0
                ? this.state.setVerticalValue
                : null
            }
            topics={topicsList}
            topicPlaceholder={topicPlaceholder}
            services={servicesList}
            verticals={verticalsList}
            changeSort={this.handleSort}
            changeTopic={this.handleTopic}
            changeService={this.handleService}
            changeVertical={this.handleVertical}
          />
          {filteredPosts.length === 0 && selectedValuesArray.length == 0 ? (
            <div>
              <CaseStudiesGrid
                className="gridAll"
                posts={posts}
                gridCardType="minimal"
              />
              <TinyPagination
                total={posts.length}
                selectedPageId={this.state.selectedPageId}
                itemPerPage={itemPerPage}
                renderBtnNumber={this.renderBtnNumber}
                maxBtnNumbers={5}
                preKey="<"
                nextKey=">"
              />
            </div>
          ) : (
            <div>
              <CaseStudiesGrid
                className="gridAll"
                posts={conditionalPosts}
                gridCardType="minimal"
              />
              {filteredPosts > conditionalPosts ? (
                <TinyPagination
                  total={filteredPosts.length}
                  selectedPageId={this.state.selectedPageId}
                  itemPerPage={itemPerPage}
                  renderBtnNumber={this.renderBtnNumber}
                  maxBtnNumbers={4}
                  preKey="<"
                  nextKey=">"
                />
              ) : (
                <TinyPagination
                  total={filteredPosts.length}
                  selectedPageId={this.state.selectedPageId}
                  itemPerPage={itemPerPage}
                  renderBtnNumber={this.renderBtnNumber}
                  maxBtnNumbers={4}
                  preKey="<"
                  nextKey=">"
                />
              )}
            </div>
          )}
          <ContactBanner
            headline={contact_headline}
            cta={contact_cta}
            url={contact_url}
          />
        </div>
      </StyledCaseStudies>
    );
  }
}

export default CaseStudies;

export const query = graphql`
  query {
    wordpressPage(slug: { eq: "case-studies" }) {
      content
      slug
      title
      type
      content
      acf {
        hero_headline
        hero_sub_headline
        intro_copy
        overview_headline
        contact_headline
        contact_cta
        contact_url
      }
    }
    allWordpressWpCaseStudy {
      edges {
        node {
          our_solutions
          topic
          vertical
          acf {
            challenge_copy
            client
            client_logo {
              source_url
            }
            contact_cta
            contact_headline
            contact_url
            excerpt
            featured_case_study
            popularity_num
            primary_topic
            quote_copy
            quote_author
            solution_copy
          }
          better_featured_image {
            source_url
          }
          slug
          title
          type
        }
      }
    }
    allWordpressCategory {
      edges {
        node {
          name
          slug
          wordpress_id
        }
      }
    }

    allWordpressWpTopic {
      edges {
        node {
          name
          slug
          wordpress_id
        }
      }
    }

    allWordpressWpOurSolutions {
      edges {
        node {
          name
          slug
          wordpress_id
        }
      }
    }

    allWordpressWpVertical {
      edges {
        node {
          name
          slug
          wordpress_id
        }
      }
    }
  }
`;
