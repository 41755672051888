import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import ReactHTMLParser from "react-html-parser";
import { breakpoints, colors, fonts } from "../../style-utilities/variables";

import ButtonGrey from "../ButtonGrey";

const Div = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  border: 2px solid ${colors.secondaryGray};
  .image-box {
    width: 100%;
    overflow: hidden; /* need overflow: hidden for ie  */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 0;
    img {
      margin-bottom: 0;
    }
  }
  .post-content {
    flex-grow: 1;
    padding: 1em;
    display: -webkit-box;
    display: -ms-flexbox;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    display: flex;
    background-color: ${colors.white};
  }

  .post-excerpt {
    flex: 1 1 auto;
    margin-bottom: 1em;
  }
`;

function randID() {
  var number = Math.random(); // 0.9394456857981651
  number.toString(36);
  var id = number.toString(36).substr(2, 9);
  return id;
}

const CaseStudiesGridItem = ({
  item,
  index,
  gridCardType,
  gridCardTags,
  className
}) => {
  if (typeof item.topic == "undefined") {
    var tagIDs = ["31"];
  } else {
    var tagIDs = item.topic;
  }

  return (
    <Div className={className} key={item.slug}>
      <div className="image-box">
        <Link to={"results/case-studies/" + item.slug}>
          <img
            src={
              item.better_featured_image
                ? item.better_featured_image.source_url
                : null
            }
            alt={
              item.better_featured_image
                ? item.better_featured_image.alt_text
                : null
            }
          />
        </Link>
      </div>
      <div className="post-content">
        <Link to={"results/case-studies/" + item.slug} className="post-title">
          {item.title}
        </Link>
        <p className="post-excerpt">{ReactHTMLParser(item.acf.excerpt)}</p>
        <div className="post-link">
          <ButtonGrey
            textLabel="READ MORE"
            pageLink={"results/case-studies/" + item.slug}
          />
        </div>
      </div>
    </Div>
  );
};

export default CaseStudiesGridItem;
